@function hexToRGB($hex) {
    @return red($hex),
    green($hex),
    blue($hex);
}

$black: #191919;
$white: #ffffff;
$blue: #48BFE3;
$purple: #6930C3;
$aqua: #80FFDB;
$yellow: #FFD700;
$dark-grey: #282828;
$light-grey: #EAEAEA;
$error-red: #FF6B6B;
$linear-from-black: linear-gradient(193deg, #6930C3 -0.59%, rgba(89, 120, 211, 0.90) 28.7%, rgba(72, 191, 227, 0.80) 57.99%, #191919 82.11%);

:root {
    //Colours
    --black: #{$black};
    --column-large: 21.43%;
    --column-small: 7.14%;
    --black-rgb: #{hexToRGB($black)};
    --white: #{$white};
    --white-rgb: #{hexToRGB($white)};
    --blue: #{$blue};
    --blue-rgb: #{hexToRGB($blue)};
    --purple: #{$purple};
    --purple-rgb: #{hexToRGB($purple)};
    --aqua: #{$aqua};
    --aqua-rgb: #{hexToRGB($aqua)};
    --yellow: #{$yellow};
    --yellow-rgb: #{hexToRGB($yellow)};
    --dark-grey: #{$dark-grey};
    --dark-grey-rgb: #{hexToRGB($dark-grey)};
    --light-grey: #{$light-grey};
    --light-grey-rgb: #{hexToRGB($light-grey)};
    --error-red: #{$error-red};
    --error-red-rgb: #{hexToRGB($error-red)};

    //Fonts
    // --font-display: 'Poppins';
    // --font-body: 'Work Sans'

}