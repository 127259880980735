@import "@/styles/main.scss";
.header {
    padding: 10px 0px;
    width: 100%;
    height: 100px;
    border-bottom: 1px solid #ffffff25;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 10000;
}

.logo-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    margin-left: 7.14%;
    z-index: 123;
    svg {
        width: 100%;
        height: auto;
    }
    @include size-under(phone) {
        svg {
            width: 80%;
            height: auto;
        }
    }
}

.hamburger {
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 25px;
    width: 40px;
    right: var(--column-small);
    cursor: pointer;
    align-items: flex-end;
    position: absolute;
    top: 40px;
    

    .line {
        width: 100%;
        height: 2px;
        background: var(--blue);
        transition-delay: 0.05s;
        transition: 0.3s all ease-out;
        
        &:first-child {
            background: var(--aqua);
            transition-delay: 0s;
        }
        &:last-child {
            transition-delay: 0.1s;
            background: var(--purple);
        }
    }
    &.opened {
        .line {
            background-color: white !important;
            &:first-child {
                transform: translateY(12px) rotate(45deg);
            }
            transform: translateY(0px) rotate(45deg);
            &:last-child {
                transform: translateY(-12px) rotate(-45deg) ;
            }
            
        }
    }
}

.mobile-menu {
    opacity: 0;
    transition: 0.3s all ease-in-out;
    pointer-events: none;
    visibility: hidden;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(rgba(105, 48, 195, 1), rgba(72, 191, 227, 1), rgba(128, 255, 219, 1));
    &.active {
        opacity: 1;
        pointer-events: all;
        visibility: visible;
    }
    .logo {
        position: absolute;
        top: 30px;
        left: var(--column-small);
    }

    .links {
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 20px;
        left: calc(var(--column-small) + var(--column-large)*2);
        top: 50%;
        transform: translateY(-50%);
        li {
            font-family: var(--font-display);
            font-size: 80px;
            font-weight: 500;
            color: #fff;
            line-height: 1;
            letter-spacing: -1px;
            transition: 0.3s color ease-in-out;
            &:hover {
                color: var(--yellow);
            }
            @include size-under(site_max) {
                font-size: 60px;
            }
            > ul {
                > li {
                    font-size: 40px;
                    font-weight: 300;
                    &:first-child {
                        margin-top: 20px;
                    }
                    margin-bottom: 10px;
                    margin-left: 20px;
                    @include size-under(tablet) {
                        font-size: 30px;
                    }
                }
            }
        }
        @include size-under(tablet_l) {
            left: var(--column-small);
            li {
                font-size: 44px;
            }
        }
    }
}

@keyframes fadeIn{
    0% {opacity: 0;}
    100% {opacity: 1;}
}