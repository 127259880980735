
body{
	font-family: var(--font-body);
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
	font-family: var(--font-display);
}

h1 {
	@include h1;
}

h2 {
	@include h2;
}

h3 {
	@include h3;
}

h4 {
	@include h4;
}

h5 {
	@include h5;
}

h6 {
	@include h6;
}

p {
	font-size: 2.125rem;
	line-height: 1.5;
	color: #FFF;
	font-weight: 300;
	max-width: 1000px;
	letter-spacing: -1.02px;
	font-family: var(--font-body);
	@include size-under(site_max) {
		font-size: 1.6rem;
	}
	@include size-under(tablet) {
		font-size: 1.375rem;
	}
}
