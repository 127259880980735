*,
*::before,
*::after {
	box-sizing: border-box;
}

ul[class],
ol[class] {
	padding: 0;
	li{
		list-style: none;
	}
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0;
}

body {
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
}

a{
	text-decoration: none;
	color: inherit;
}

input,
button,
textarea,
select {
	font: inherit;
}


img {
	max-width: 100%;
	height: auto;
	display: block;
}