@import "@/styles/main.scss";
.footer {
    display: flex;
    background: #191919;
    width: 100%;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    padding: 80px var(--column-small) 100px var(--column-small);
    z-index: 123123123;
    .links {
        display: flex;
        flex-direction: row;
        width: 100%;
        @include size-under(tablet_l) {
            flex-direction: column;
        }
    }
    .logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        z-index: 123;
        svg {
            width: 100%;
            height: auto;
        }
        @include size-under(phone) {
            svg {
                width: 80%;
                height: auto;
            }
        }
        
    }
    ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        color: #ffffff;
        list-style: none;
        gap: var(--column-small);
        width: 100%;
        @include size-under(tablet_l) {
            justify-content: flex-start;
            margin-top: 30px;
            margin-bottom: 40px;
            @include size-under(phone) {
                margin-bottom: 2px;
            }
        }
        @include size-under(phone) {
            flex-direction: column;
        }
        > li {
            font-weight: medium;
            font-size: 1.2rem;
            font-family: var(--font-display);
            > ul {
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-top: 20px;
                @include size-under(phone) {
                    margin-top: 5px;
                }
                li {
                    font-size: 1rem;
                    font-weight: normal;
                    @include size-under(phone) {
                        &:last-child {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
    .copyright {
        color: #ffffff;
        font-family: var(--font-body);
        @include size-under(phone) {
            margin-top: 30px;
        }
    }

    a {
        color: var(--white);
        transition: 0.3s all ease-in-out;
        &:hover {
            color: var(--blue);
        }
    }
}
