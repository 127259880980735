@import "@/styles/main.scss";
.section {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    z-index: 111;
    @include size-under(phone) {
        opacity: 0.5;
    }
}

.column {
    width: 21.43%;
    height: 100%;
    background-color: transparent;
    &:first-child {
        width: 7.14%;
    }
    &:last-child {
        width: 7.14%;
        border-right: 0px;
    }

    &:nth-child(1n + 1) {
        .line {
            animation-delay: 0s;
        }
    }
    &:nth-child(2n + 2) {
        .line {
            animation-delay: 0.2s;
        }
    }
    &:nth-child(3n + 3) {
        .line {
            animation-delay: 0.3s;
        }
    }
    &:nth-child(4n + 4) {
        .line {
            animation-delay: 0.4s;
        }
    }
    &:nth-child(5n + 5) {
        .line {
            animation-delay: 0.5s;
        }
    }

    .line {
        height: 100%;
        width: 1px;
        background: linear-gradient(180deg, rgba(255,255,255,.08) 0%, rgba(255,255,255,.2) 50%, rgba(255,255,255,0.08) 100%);
        background-size: 100% 300%;
        animation: slide 15s linear infinite;
        
    }
}

@keyframes slide {
    0% {
        background-position: 0 200%;
    }
    100% {
        background-position: 0 -100%;
    }
}
